import React, { useEffect, useState } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import NewsUpdates from '../../components/news-updates/NewsUpdates';
import AdTelegram from '../../components/ad-telegram/AdTelegram';
import FreeBetsOptions from '../../components/free-bets-options/FreeBetsOptions';
import { Link, useNavigate } from 'react-router-dom';
import FreeExpertSingles from '../../components/free-expert-singles/FreeExpertSingles';
import AccumulatorTip from '../../components/accumulator-tip/AccumulatorTip';
import { useGlobalContext } from '../../context/context';
import axios from 'axios';
import { AccumulatorTipSkeleton } from '../../components/loaders/Loaders';

const Home = () => {
  return (
    <main className='home-page'>
      <Header />
      <FreeAccumulatorTips />
      <FreeExpertSingles />
      <AdTelegram />
      <NewsUpdates />
    </main>
  );
};

const Header = () => {
  const { telegramLink } = useGlobalContext();

  return (
    <section className='header'>
      <FreeBetsOptions />

      <h1>
        KMANALYSIS <br />
        Free Betting Tips
      </h1>
      <p className='brief'>
        KManalysis is a sports consultancy brand with a modern age pecularity
        that provides a bridge of consistent income for its audience by arming
        them with accurate prediction of sporting events through the expertise
        of competent analysts in the field. We also bring you timely sport
        related news and recreational activities.
      </p>
      <div className='btns'>
        <a href={telegramLink} target='_blank' rel='noreferrer'>
          <button className='blue'>Join Telegram Channel</button>
        </a>
        <Link to='/sign-up'>
          <button className='white'>
            Get Started <BsArrowRight />
          </button>
        </Link>
      </div>
      <p className='trusted'>Trusted by over 600k members worldwide</p>
    </section>
  );
};

const FreeAccumulatorTips = () => {
  const navigate = useNavigate();

  const [freeAccaTips, setFreeAccaTips] = useState([]);
  const [loading, setLoading] = useState(false);
  const { baseURL } = useGlobalContext();

  const getFreeAccumulatorTips = async () => {
    try {
      // setLoading(true);
      const { data } = await axios.get(`${baseURL}/free-accumulator-tips`);
      setLoading(false);
      setFreeAccaTips(data.tips);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getFreeAccumulatorTips();
    // eslint-disable-next-line
  }, []);

  return (
    <section className='free-accumulator-tips'>
      <div className='title-btn'>
        <h2 className='title'>
          Free <span>Accumulator</span> Tips
        </h2>
        <button
          className='blue'
          onClick={() => navigate(`/free-accumulator-tips`)}
        >
          View All <BsArrowRight />
        </button>
      </div>

      {!loading && freeAccaTips.length < 1 ? (
        <div className='tips-unavailable'>
          <h2>Free Accumulator Tips Currently Unavailable</h2>
        </div>
      ) : (
        <>
          <div className='tips'>
            {loading ? (
              <AccumulatorTipSkeleton cards={3} />
            ) : (
              <>
                {freeAccaTips.slice(0, 1).map((tip, tipIndex) => {
                  const { matchTime, odds, bookies, matches } = tip;
                  return (
                    <AccumulatorTip
                      bookies={bookies}
                      matchTime={matchTime}
                      odds={odds}
                      key={tipIndex}
                      matches={matches}
                    />
                  );
                })}
              </>
            )}
          </div>

          <div className='btn'>
            <button
              className='blue'
              onClick={() => navigate(`/free-accumulator-tips`)}
            >
              View All <BsArrowRight />
            </button>
          </div>
        </>
      )}
    </section>
  );
};

export default Home;
