import { FiMail } from 'react-icons/fi';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../context/context';
import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import axios from 'axios';
import spinner from '../../assets/rolling-spinner-white.svg';

export const SignIn = () => {
  const navigate = useNavigate();
  const { baseURL, revealPassword } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: ``,
      password: ``,
    },
    validationSchema: Yup.object({
      email: Yup.string().email(`Invalid Email`).required(`Email is required`),
      password: Yup.string().required('Password is required'),
    }),
    onSubmit() {
      login();
    },
  });

  // FUNCTION TO LOGIN USER
  const login = async () => {
    const { email, password } = formik.values;
    const formData = new FormData();
    formData.append(`email`, email.toLowerCase());
    formData.append(`password`, password);
    try {
      setLoading(true);
      const { data } = await axios.post(`${baseURL}/auth/login`, formData);
      setLoading(false);
      sessionStorage.setItem(`userToken`, JSON.stringify(data.token));
      navigate(`/`);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <main className='auth-page sign-in'>
      <h1 className='title'>Sign In</h1>
      <form action='' onSubmit={formik.handleSubmit}>
        {/* EMAIL */}
        <div className='form-control'>
          <label
            htmlFor='email'
            className={
              formik.touched.email && formik.errors.email ? `error` : null
            }
          >
            {formik.touched.email && formik.errors.email
              ? formik.errors.email
              : ` Email`}
          </label>
          <div className='email-cont'>
            <FiMail />
            <input
              type='email'
              placeholder='example@gmail.com'
              id='email'
              name='email'
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
        </div>

        {/* PASSWORD */}
        <div className='form-control'>
          <label
            htmlFor='password'
            className={
              formik.touched.password && formik.errors.password ? `error` : null
            }
          >
            {formik.touched.password && formik.errors.password
              ? formik.errors.password
              : `Password`}
          </label>
          <div className='password-cont'>
            <input
              type='password'
              placeholder='••••••'
              id='password'
              name='password'
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />

            {passwordVisible ? (
              <BsEyeSlash
                onClick={() => {
                  setPasswordVisible(false);
                  revealPassword(`#password`);
                }}
              />
            ) : (
              <BsEye
                onClick={() => {
                  setPasswordVisible(true);
                  revealPassword(`#password`);
                }}
              />
            )}
          </div>
        </div>

        {/* SUBMIT BTN */}
        <div className='btn'>
          <button className='blue' type='submit'>
            Sign In
            {loading ? <img src={spinner} alt='' /> : null}
          </button>
        </div>

        {/* ALTERNATIVE */}
        <p className='alternative'>
          Don't have an account? <Link to='/sign-up'>Sign up</Link>
        </p>
        <p className='alternative'>
          Forgot your password?{' '}
          <Link to='/forgot-password'>Reset Password</Link>
        </p>
      </form>
    </main>
  );
};

export const SignUp = () => {
  const navigate = useNavigate();
  const { baseURL, revealPassword } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: ``,
      email: ``,
      password: ``,
      confirmPassword: ``,
    },
    validationSchema: Yup.object({
      email: Yup.string().email(`Invalid Email`).required(`Email is required`),

      name: Yup.string().required(`Name is required`),

      password: Yup.string().required('Password is required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
    }),
    onSubmit() {
      register();
    },
  });

  // FUNCTION TO REGISTER USER
  const register = async () => {
    const { email, name, password } = formik.values;
    const formData = new FormData();
    formData.append(`name`, name.toLowerCase());
    formData.append(`email`, email.toLowerCase());
    formData.append(`password`, password);
    try {
      setLoading(true);
      const { data } = await axios.post(`${baseURL}/auth/register`, formData);
      setLoading(false);
      sessionStorage.setItem(`userToken`, JSON.stringify(data.token));
      toast.success(`Successful`);
      navigate(`/`);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <main className='auth-page sign-up'>
      <h1 className='title'>Sign Up</h1>
      <form action='' onSubmit={formik.handleSubmit}>
        {/* NAME */}
        <div className='form-control'>
          <label
            htmlFor='name'
            className={
              formik.touched.name && formik.errors.name ? `error` : null
            }
          >
            {formik.touched.name && formik.errors.name
              ? formik.errors.name
              : ` Name`}
          </label>
          <input
            type='text'
            placeholder='Enter full name'
            id='name'
            name='name'
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>

        {/* EMAIL */}
        <div className='form-control'>
          <label
            htmlFor='email'
            className={
              formik.touched.email && formik.errors.email ? `error` : null
            }
          >
            {formik.touched.email && formik.errors.email
              ? formik.errors.email
              : ` Email`}
          </label>
          <div className='email-cont'>
            <FiMail />
            <input
              type='email'
              placeholder='example@gmail.com'
              id='email'
              name='email'
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
        </div>

        {/* PASSWORD */}
        <div className='form-control'>
          <label
            htmlFor='password'
            className={
              formik.touched.password && formik.errors.password ? `error` : null
            }
          >
            {formik.touched.password && formik.errors.password
              ? formik.errors.password
              : `Password`}
          </label>
          <div className='password-cont'>
            <input
              type='password'
              placeholder='••••••'
              id='password'
              name='password'
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />

            {passwordVisible ? (
              <BsEyeSlash
                onClick={() => {
                  setPasswordVisible(false);
                  revealPassword(`#password`);
                }}
              />
            ) : (
              <BsEye
                onClick={() => {
                  setPasswordVisible(true);
                  revealPassword(`#password`);
                }}
              />
            )}
          </div>
        </div>

        {/* CONFIRM PASSWORD */}
        <div className='form-control'>
          <label
            htmlFor='confirmPassword'
            className={
              formik.touched.confirmPassword && formik.errors.confirmPassword
                ? `error`
                : null
            }
          >
            {formik.touched.confirmPassword && formik.errors.confirmPassword
              ? formik.errors.confirmPassword
              : `Confirm Password`}
          </label>
          <input
            type='password'
            placeholder='••••••'
            id='confirmPassword'
            name='confirmPassword'
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>

        {/* SUBMIT BTN */}
        <div className='btn'>
          <button className='blue' type='submit'>
            Sign Up
            {loading ? <img src={spinner} alt='' /> : null}
          </button>
        </div>

        {/* ALTERNATIVE */}
        <p className='alternative'>
          Already have an account? <Link to='/sign-in'> Sign in</Link>
        </p>
      </form>
    </main>
  );
};

export const ForgotPassword = () => {
  const { baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: ``,
    },
    validationSchema: Yup.object({
      email: Yup.string().email(`Invalid Email`).required(`Email is required`),
    }),
    onSubmit() {
      sendResetRequest();
    },
  });

  // FUNCTION TO REQUEST RESET EMAIL
  const sendResetRequest = async () => {
    const { email } = formik.values;
    const formData = new FormData();
    formData.append(`email`, email.toLowerCase());
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${baseURL}/auth/forgot-password`,
        formData
      );
      setLoading(false);
      toast.success(data.msg);
    } catch (error) {
      setLoading(false);
      // toast.error(error.response.data.msg);
      toast.error(`Please contact customer support`);
    }
  };

  return (
    <main className='auth-page sign-in'>
      <h1 className='title'>Forgot Password</h1>
      <form action='' onSubmit={formik.handleSubmit}>
        {/* EMAIL */}
        <div className='form-control'>
          <label
            htmlFor='email'
            className={
              formik.touched.email && formik.errors.email ? `error` : null
            }
          >
            {formik.touched.email && formik.errors.email
              ? formik.errors.email
              : ` Email`}
          </label>
          <div className='email-cont'>
            <FiMail />
            <input
              type='email'
              placeholder='example@gmail.com'
              id='email'
              name='email'
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
        </div>

        {/* SUBMIT BTN */}
        <div className='btn'>
          <button className='blue' type='submit'>
            Request Reset Link
            {loading ? <img src={spinner} alt='' /> : null}
          </button>
        </div>

        {/* ALTERNATIVE */}
        <p className='alternative'>
          Don't want to reset your password? <Link to='/sign-in'>Sign In</Link>
        </p>
      </form>
    </main>
  );
};

export const ResetPassword = () => {
  const { baseURL, revealPassword } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get(`email`);
  const token = queryParams.get(`token`);

  const formik = useFormik({
    initialValues: {
      password: ``,
      confirmPassword: ``,
    },
    validationSchema: Yup.object({
      password: Yup.string().required('Password is required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
    }),
    onSubmit() {
      updatePassword();
    },
  });

  const updatePassword = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post(`${baseURL}/auth/reset-password`, {
        ...formik.values,
        email: email,
        token: token,
      });
      setLoading(false);
      toast.success(data.msg);
      navigate(`/login`);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
      navigate(`/forgot-password`);
    }
  };
  return (
    <main className='auth-page sign-in'>
      <h1 className='title'>Forgot Password</h1>
      <form action='' onSubmit={formik.handleSubmit}>
        {/* PASSWORD */}
        <div className='form-control'>
          <label
            htmlFor='password'
            className={
              formik.touched.password && formik.errors.password ? `error` : null
            }
          >
            {formik.touched.password && formik.errors.password
              ? formik.errors.password
              : `New Password`}
          </label>
          <div className='password-cont'>
            <input
              type='password'
              placeholder='••••••'
              id='password'
              name='password'
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />

            {passwordVisible ? (
              <BsEyeSlash
                onClick={() => {
                  setPasswordVisible(false);
                  revealPassword(`#password`);
                }}
              />
            ) : (
              <BsEye
                onClick={() => {
                  setPasswordVisible(true);
                  revealPassword(`#password`);
                }}
              />
            )}
          </div>
        </div>

        {/* CONFIRM PASSWORD */}
        <div className='form-control'>
          <label
            htmlFor='confirmPassword'
            className={
              formik.touched.confirmPassword && formik.errors.confirmPassword
                ? `error`
                : null
            }
          >
            {formik.touched.confirmPassword && formik.errors.confirmPassword
              ? formik.errors.confirmPassword
              : `Confirm New Password`}
          </label>
          <input
            type='password'
            id='confirmPassword'
            name='confirmPassword'
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder='••••••'
          />
        </div>

        {/* SUBMIT BTN */}
        <div className='btn'>
          <button className='blue' type='submit'>
            Update Password
            {loading ? <img src={spinner} alt='' /> : null}
          </button>
        </div>

        {/* ALTERNATIVE */}
        <p className='alternative'>
          Don't want to reset your password? <Link to='/sign-in'>Sign In</Link>
        </p>
      </form>
    </main>
  );
};
