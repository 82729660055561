import React, { useEffect, useState } from 'react';
import FreeBetsOptions from '../../components/free-bets-options/FreeBetsOptions';
import AdTelegram from '../../components/ad-telegram/AdTelegram';
import AccumulatorTip from '../../components/accumulator-tip/AccumulatorTip';
import axios from 'axios';
import { useGlobalContext } from '../../context/context';
import { AccumulatorTipSkeleton } from '../../components/loaders/Loaders';

const FreeAccumulatorTips = () => {
  const [freeAccaTips, setFreeAccaTips] = useState([]);
  const [loading, setLoading] = useState(true);
  const { baseURL } = useGlobalContext();

  const getFreeAccumulatorTips = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/free-accumulator-tips`);
      setLoading(false);
      setFreeAccaTips(data.tips);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getFreeAccumulatorTips();
    // eslint-disable-next-line
  }, []);

  return (
    <main className='free-accumulator-tips-page'>
      <div className='options'>
        <FreeBetsOptions itemBgColor={`#509eff`} />
      </div>
      <h1 className='title'>
        Free <span>Accumulator</span> Tips
      </h1>

      {!loading && freeAccaTips.length < 1 ? (
        <div className='tips-unavailable'>
          <h2>Free Accumulator Tips Currently Unavailable</h2>
        </div>
      ) : (
        <div className='tips'>
          {loading ? (
            <AccumulatorTipSkeleton cards={6} />
          ) : (
            <>
              {freeAccaTips.map((tip, tipIndex) => {
                const { matchTime, odds, bookies, matches } = tip;
                return (
                  <AccumulatorTip
                    bookies={bookies}
                    matchTime={matchTime}
                    odds={odds}
                    key={tipIndex}
                    matches={matches}
                  />
                );
              })}
            </>
          )}
        </div>
      )}

      <AdTelegram />
    </main>
  );
};

export default FreeAccumulatorTips;
