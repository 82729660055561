import { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';
import { SkeletonTheme } from 'react-loading-skeleton';
import axios from 'axios';

// PAGES WITHOUT AUTH
import {
  AboutUs,
  ContactUs,
  DoubleChance,
  ErrorPage,
  ExpertSingle,
  ExpertSingles,
  ForgotPassword,
  FreeAccumulatorTips,
  Handicap,
  Home,
  LeagueStandings,
  NewsUpdates,
  Offers,
  OverOnePointFive,
  OverTwoPointFive,
  ResetPassword,
  SignIn,
  SignUp,
  SingleNewsUpdate,
} from './pages';
import { useGlobalContext } from './context/context';
import Footer from './components/Footer/Footer';
import Navbar from './components/Navbar/Navbar';
import Bttsgg from './pages/bttsgg/Bttsgg';
import PopUp from './components/pop-up/PopUp';
import GifBanner from './components/gif-banner/GifBanner';
import MetaDecorator from './components/MetaDecorator/MetaDecorator';

function App() {
  const { pathname } = useLocation();
  const { showNav } = useGlobalContext();
  const [popups, setPopups] = useState([]);
  const [gifs, setGifs] = useState([]);
  const { baseURL } = useGlobalContext();
  const [showPopup, setShowPopup] = useState(false);
  const [showGifs, setShowGifs] = useState(false);

  useEffect(() => {
    if (showNav) {
      document.body.style.overflow = `hidden`;
    } else {
      document.body.style.overflow = `auto`;
    }
  }, [showNav]);

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [pathname]);

  const getPopups = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/popups`);
      setPopups(data.popups);
      setTimeout(() => {
        setShowPopup(true);
      }, 1000);
    } catch (error) {}
  };

  const getGifs = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/gifs`);
      setGifs(data.gifs);
      setTimeout(() => {
        setShowGifs(true);
      }, 1000);
    } catch (error) {}
  };

  useEffect(() => {
    getPopups();
    getGifs();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <MetaDecorator
        title={'KManalysis - Expert Soccer Analysis & Accurate Predictions'}
        description={`Get expert soccer analysis and accurate soccer predictions with today's sure odds, providing you with the best football betting tips, high odds, and winning strategies for soccer match predictions and results from KManalysis.`}
      />
      <Navbar activePage={pathname} />
      <SkeletonTheme baseColor='#fff' highlightColor='#0162c8'>
        <Routes>
          <Route path='/sign-in' element={<SignIn />} />
          <Route path='/sign-up' element={<SignUp />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/reset-password' element={<ResetPassword />} />
          <Route path='/' element={<Home />} />
          <Route
            path='/free-accumulator-tips'
            element={<FreeAccumulatorTips />}
          />
          <Route path='/expert-singles' element={<ExpertSingles />} />
          <Route path='/expert-singles/:id' element={<ExpertSingle />} />
          <Route path='/news-updates' element={<NewsUpdates />} />
          <Route path='/news-updates/:id' element={<SingleNewsUpdate />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/contact-us' element={<ContactUs />} />
          <Route path='/over-1.5-tips' element={<OverOnePointFive />} />
          <Route path='/over-2.5-tips' element={<OverTwoPointFive />} />
          <Route path='/bttsgg-tips' element={<Bttsgg />} />
          <Route path='/double-chance-tips' element={<DoubleChance />} />
          <Route path='/handicap-tips' element={<Handicap />} />
          <Route path='/league-standings' element={<LeagueStandings />} />
          <Route path='/offers' element={<Offers />} />
          <Route path='*' element={<ErrorPage />} />
        </Routes>
        <ToastContainer transition={Slide} autoClose={1000} />
      </SkeletonTheme>
      <Footer />
      {popups.length > 0 && showPopup && (
        <PopUp
          data={popups[Math.floor(Math.random() * popups.length)]}
          dismissFunc={setShowPopup}
        />
      )}
      {gifs.length > 0 && showGifs && (
        <GifBanner data={gifs[0]} dismissFunc={setShowGifs} />
      )}
    </>
  );
}

export default App;
