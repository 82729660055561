import moment from 'moment';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import oneXBetLogo from '../../assets/1xbet-logo-2.png';
import betanoLogo from '../../assets/betano_logo.svg';
import betwinnerLogo from '../../assets/betwinner-logo.svg';
import zebetLogo from '../../assets/zebet-logo.png';
import sportybetLogo from '../../assets/sportybet-logo.jpg';
import bet9jaLogo from '../../assets/bet9ja-logo.png';
import twentytwoBetLogo from '../../assets/22bet-logo.png';
import paripesaLogo from '../../assets/paripesa-logo.jpg';
import { FaChevronDown, FaCopy } from 'react-icons/fa';
import { BsFlagFill, BsFillQuestionCircleFill } from 'react-icons/bs';
import { FaCircleCheck, FaCircleXmark } from 'react-icons/fa6';

const AccumulatorTip = ({ bookies, matches }) => {
  const [showCode, setShowCode] = useState(false);
  const [copyCode, setCopyCode] = useState(false);
  const userToken = JSON.parse(sessionStorage.getItem(`userToken`));

  return (
    <>
      <div className='accumulator-tip'>
        <div className='table-head'>
          <p>
            <BsFlagFill />
          </p>
          <p>Match</p>
          <p className='tip'>Tip</p>
          <p>Time</p>
          <p className='status'>Status</p>
        </div>

        <div className='games'>
          {matches.map((match) => {
            const {
              homeTeam,
              awayTeam,
              matchTime,
              prediction,
              league,
              _id,
              status,
            } = match;
            return (
              <div className='acca-prediction' key={_id}>
                <h4 className='league'>{league}</h4>
                <div className='teams'>
                  <h3>{homeTeam}</h3>
                  <span>vs</span>
                  <h3>{awayTeam}</h3>
                </div>
                <h4 className='tip'>{prediction}</h4>
                <p className='time'>{moment(matchTime).format(`HH:mm`)}</p>
                <div className='cont'>
                  {status === `pending` ? (
                    <BsFillQuestionCircleFill className={status} />
                  ) : status === `won` ? (
                    <FaCircleCheck className={status} />
                  ) : status === `lost` ? (
                    <FaCircleXmark className={status} />
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>

        <div
          className={`bookies ${showCode ? `show` : ``}`}
          style={{
            height: showCode ? bookies.length * 60 + 0 : 0,
          }}
        >
          {copyCode ? <span className='copied'>Copied!</span> : null}
          {bookies.map((bookie) => {
            const { name, link, code, _id } = bookie;
            return (
              <div className='bookie' key={_id}>
                <div className='img'>
                  {name === `zebet` ? (
                    <img src={zebetLogo} alt='zebet' />
                  ) : name === `1xbet` ? (
                    <img src={oneXBetLogo} alt='1xbet' />
                  ) : name === `betano` ? (
                    <img src={betanoLogo} alt='betano' />
                  ) : name === `betwinner` ? (
                    <img src={betwinnerLogo} alt='betwinner' />
                  ) : name === `sportybet` ? (
                    <img src={sportybetLogo} alt='sportybet' />
                  ) : name === `bet9ja` ? (
                    <img src={bet9jaLogo} alt='bet9ja' />
                  ) : name === `22bet` ? (
                    <img src={twentytwoBetLogo} alt='22bet' />
                  ) : name === `paripesa` ? (
                    <img src={paripesaLogo} alt='paripesa' />
                  ) : null}
                </div>
                <div
                  className='code'
                  onClick={(e) => {
                    navigator.clipboard.writeText(code);
                    setCopyCode(true);
                    setTimeout(() => {
                      setCopyCode(false);
                    }, 1500);
                  }}
                >
                  <div className='info'>
                    <h3>{code}</h3>
                    <FaCopy />
                  </div>
                </div>
                <a
                  href={link}
                  target='_blank'
                  rel='noreferrer'
                  className='link'
                >
                  <button>BET HERE</button>
                </a>
              </div>
            );
          })}
        </div>

        <div className='view-code'>
          <p
            onClick={(e) => {
              if (userToken) {
                setShowCode(!showCode);
              } else {
                toast.error(`Please sign in to access codes`);
              }
            }}
          >
            View Code <FaChevronDown className={showCode ? `rotate` : ``} />
          </p>
        </div>
      </div>
    </>
  );
};

export default AccumulatorTip;
