import React from 'react';
import MetaDecorator from '../../components/MetaDecorator/MetaDecorator';

const AboutUs = () => {
  return (
    <>
      <MetaDecorator
        description={`Learn more about KManalysis, our mission, and our team.`}
        title={`About Us - KManalysis`}
      />
      <main className='about-us-page'>
        {/* ABOUT KMA */}
        <section className='block'>
          <h1 className='title'>
            ABOUT <span>KMANALYSIS</span>
          </h1>
          <p>
            KMAnalysis is a Nigerian sport consultancy with a modern age
            pecularity that provide a bridge of consistent income for its
            audience by arming them with accurate prediction of sporting events
            through the expertise of competent analysts in the field. They are
            also known for bringing timely sport related news and recreational
            activities for their audience.
          </p>
          <h3>Our Purpose</h3>
          <p>
            We are here to help our audience create a consistent flow of income.
            Our Vision We aim to become one of Africa's leading consulting
            brands in helping her youth become financially competent.
          </p>
          <h3>Our Mission</h3>
          <p>
            We use cyberspace (traditionally social media) as a medium to
            provide our audience with well analysized prognosis for sporting
            events daily.
          </p>
          <h3>Why Trust Us?</h3>
          <p>
            We are a proven and well renowned brand that have gained the trust
            of thousands of people across the globe. We are a registered
            business with the Corporate Affairs Commission, Nigeria (CAC) with
            the registration number: BN-3532581. We have also been issued a
            SCUML verification by the Economic and Financial Crimes Commission
            to further clarify our transparency
          </p>
        </section>

        {/* TERMS AND CONDITIONS */}
        <section className='block'>
          <h1 className='title'>
            TERMS & <span>CONDITION</span>
          </h1>
          <p>
            By using kmanalysis.com, you are agreeing to the following Terms and
            Conditions of Use. There are only a few terms and conditions - we
            like to keep it nice and clear for our users. Firstly, we'd just
            like to offer a friendly reminder that you are responsible for
            complying with any relevant laws and regulations that apply in your
            region. Also, all the great KMANALYSIS content and tools produced by
            us are protected by copyright. And that's all the legal mumbo jumbo
            we need to get across.
          </p>
          <h3>License of Use</h3>
          <p>
            By using kmanalysis.com you grant us an irrevocable, non-exclusive,
            world-wide, royalty-free license (including the right to
            sub-license) to use, copy, modify, publish and share your betting
            tips and other content you provide through any media anywhere and to
            allow any third party to do the same. Likewise, you are free to use
            all the content on kmanalysis free of charge. Similarly, feel free
            to republish or share any of our content on other websites or across
            social media. All we ask is that you please link back to the
            original source. We want our content to reach as many people as
            possible so redistribute, republish and share to your heart's
            content - just always remember to link us.
          </p>
          <h3>Limitation</h3>
          <p>
            We encourage and promote the individual responsibility of our
            community members and users. With this philosophy in mind, we'd like
            to remind you that kmanalysis is not liable for any damages or
            losses suffered from the use of our content or the inability to use
            our content properly. Yes, we're covering our behinds. But hey, we
            have to. We work hard to ensure that all the content found on
            kmanalysis is reliable, informative and helpful but it's a huge site
            and as such difficult to monitor absolutely everything especially
            content which is created by our community. It's a two way street and
            although we are confident our content is second to none, it is also
            dependent on its application. However, we are also confident you
            will use any content found on our site sensibly and responsibly.
          </p>
          <h3>REVISIONS AND ERRATA</h3>
          <p>
            Ok, confession time. Sometimes we might make an error. It could be a
            technical error, a tpyo, or even a photographic error (don't worry,
            we're not entirely sure what a photographic error would look like
            either). And yes, some of our content might even be out of date or
            incomplete. We will strive to ensure this doesn't happen but it very
            occasionally will occur. The industry moves quickly and is
            perpetually changing. If you happen to come across anything on
            kmanalysis that you'd like to see updated or you feel is incomplete,
            let us know and we'll fix it up asap.
          </p>
          <h3>LINKS</h3>
          <p>
            KMANALYSIS is a busy place with plenty of great content and we link
            to a lot of other sites too. While we might endorse the content we
            are directly linking to, that endorsement doesn't necessarily extend
            to the entire site. There could well be some content on any linked
            site that we don't agree with or perhaps even offends our commitment
            to our principles of equality and anti-discrimination. The web is a
            wild place. But we're sure you know that already. There may be times
            when we wish to update these terms and conditions. It's unlikely to
            happen often but as the internet and betting industry are constantly
            evolving, there may be occasions when we need to make some changes.
          </p>
          <h3>BETTING TIPS</h3>
          <p>
            When you post a tip on KMANALYSIS, it will remain on KMANALYSIS as
            part of the content of our site, even if you choose to close your
            account. Why would we want to deny other users a good tip?
          </p>
        </section>

        {/* PRIVACY POLICY & DISCLAIMER */}
        <section className='block'>
          <h1 className='title'>PRIVACY POLICY & DISCLAIMER</h1>
          <p>
            The information on this site is intended to furnish users with
            general information on sports predictions, and betting advisory
            among other betting-related analyses. The information contained and
            accessed on this site www.kmanalysis.com is provided by KMANALYSIS
            for general sports predictions guidance and it is not intended to
            substitute for any investment scheme whatsoever.
          </p>
          <p>
            However, there are no expressed or implicit warranties nor
            representations with regards to the content's accuracy and/or
            completeness. You are advised to consult with appropriate KMANALYSIS
            customer service representatives via the channels provided on the
            website for inquiries and advice regarding any of our service
            offerings. KMANALYSIS shall not be held responsible and/or liable
            for any consultation made outside the contact channels provided on
            this website.
          </p>
          <h3>BN - 3532581 KMANALYSIS</h3>
        </section>
      </main>
    </>
  );
};

export default AboutUs;
