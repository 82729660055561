import React, { useEffect, useState } from 'react';
import { SubNewsCard } from '../../components/News-Card/NewsCard';
import { useGlobalContext } from '../../context/context';
import axios from 'axios';
import { PageLoader } from '../../components/loaders/Loaders';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import MetaDecorator from '../../components/MetaDecorator/MetaDecorator';

const SingleNewsUpdate = () => {
  const [news, setNews] = useState({});
  const { baseURL, adOne, adTwo } = useGlobalContext();
  const [loading, setLoading] = useState(true);
  const [moreNews, setMoreNews] = useState([]);
  const { id } = useParams();

  const getSpecificNews = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/news/${id}`);
      setLoading(false);
      setNews(data[0]);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getAllNews = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/news`);
      setLoading(false);
      setMoreNews(data.news.filter((news) => news._id !== id).slice(0, 5));
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    // navigate(0);
    getSpecificNews();
    getAllNews();
    // eslint-disable-next-line
  }, [id]);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <main className='single-news-update-page'>
      <MetaDecorator title={news.title} />
      {/* NEWS */}
      <div className='news'>
        <p className='sport-type'>{news.sportCategory}</p>
        <h1 className='title'>{news.title}</h1>
        <p className='time-date'>{moment(news.createdAt).format('lll')}</p>
        <div className='img'>
          <img
            src={`https://api.kmanalysis.com/${news.image}`}
            alt={news.title}
          />
        </div>
        <p
          className='post-info'
          dangerouslySetInnerHTML={{ __html: news?.content }}
        ></p>

        {adOne.adLink?.toLowerCase() === `no ad` ? null : (
          <article className='ad-space second'>
            <a href={adTwo.adLink} target='_blank' rel='noreferrer'>
              <img
                src={`https://api.kmanalysis.com/${adTwo.adImage}`}
                alt={adTwo.adCaption}
              />
              <h3 className='caption'>{adTwo.adCaption}</h3>
            </a>
          </article>
        )}
      </div>

      {/* MORE MEWS */}
      <section className='more-news'>
        <h2 className='title'>More News</h2>

        <div className='extra'>
          {moreNews.slice(1, 4).map((news) => {
            const { sportCategory, createdAt, title, image, _id } = news;
            return (
              <SubNewsCard
                sportCategory={sportCategory}
                timePosted={createdAt}
                title={title}
                image={image}
                id={_id}
                key={_id}
              />
            );
          })}
        </div>
      </section>
    </main>
  );
};

export default SingleNewsUpdate;
