import moment from "moment/moment";
import React from "react";
import { Link } from "react-router-dom";

export const MainNewsCard = ({
  sportCategory,
  title,
  timePosted,
  content,
  image,
  id,
}) => {
  const regex = /(<([^>]+)>)/gi;
  return (
    <Link to={`/news-updates/${id}`} className="main-news-card">
      <div className="img">
        <img src={`https://api.kmanalysis.com/${image}`} alt={title} />
      </div>
      <div className="info">
        <h5 className="sport">{sportCategory}</h5>
        <h3 className="title">{title}</h3>
        <p className="time-date">{moment(timePosted).format("lll")}</p>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: content.replace(regex, "") }}
        />
      </div>
    </Link>
  );
};

export const SubNewsCard = ({
  sportCategory,
  title,
  timePosted,
  image,
  id,
}) => {
  return (
    <Link to={`/news-updates/${id}`} className="sub-news-card">
      <div className="info">
        <h5 className="sport">{sportCategory}</h5>
        <h3 className="title">{title}</h3>
        <p className="time-date">{moment(timePosted).format("lll")}</p>
      </div>
      <div className="img">
        <img src={`https://api.kmanalysis.com/${image}`} alt={title} />
      </div>
    </Link>
  );
};
