import React from 'react';
import { Link } from 'react-router-dom';
import { useGlobalContext } from '../../context/context';

const FreeBetsOptions = ({ itemBgColor }) => {
  const { predict4MeLink } = useGlobalContext();

  return (
    <div className='free-bets-options'>
      <ul>
        <li style={{ backgroundColor: itemBgColor }}>
          <a className='blue-button' href={predict4MeLink} target='_blank' rel='noreferrer'>
            Predict 4 Me 🔥
          </a>
        </li>
        <li style={{ backgroundColor: itemBgColor }}>
          <Link to='/over-1.5-tips'>Over 1.5</Link>
        </li>
        <li style={{ backgroundColor: itemBgColor }}>
          <Link to='/over-2.5-tips'>Over 2.5</Link>
        </li>
        <li style={{ backgroundColor: itemBgColor }}>
          <Link to='/bttsgg-tips'>BTTS/GG</Link>
        </li>
        <li style={{ backgroundColor: itemBgColor }}>
          <Link to='/double-chance-tips'>Double Chance</Link>
        </li>
        <li style={{ backgroundColor: itemBgColor }}>
          <Link to='/handicap-tips'>Handicap</Link>
        </li>
      </ul>
    </div>
  );
};

export default FreeBetsOptions;
