import React, { useEffect, useState } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import ExpertSingle from '../expert-single/ExpertSingle';
import { ExpertSingleTipSkeleton } from '../loaders/Loaders';
import axios from 'axios';
import { useGlobalContext } from '../../context/context';
import { useNavigate } from 'react-router-dom';

const FreeExpertSingles = () => {
  const [expertSingles, setExpertSingles] = useState([]);
  const [loading, setLoading] = useState(true);
  const { baseURL } = useGlobalContext();
  const navigate = useNavigate();

  const getExpertSingles = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/expert-singles-tips`);
      setLoading(false);
      setExpertSingles(data.tips);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getExpertSingles();
    // eslint-disable-next-line
  }, []);

  return (
    <section className='free-expert-singles'>
      <div className='title-btn'>
        <h2 className='title'>
          Free <span>Expert</span> Singles
        </h2>
        <button className='blue' onClick={() => navigate(`/expert-singles`)}>
          View All <BsArrowRight />
        </button>
      </div>

      {!loading && expertSingles.length < 1 ? (
        <div className='tips-unavailable'>
          <h2>Free Expert Singles Currently Unavailable</h2>
        </div>
      ) : (
        <div className='expert-singles'>
          {loading ? (
            <ExpertSingleTipSkeleton cards={3} />
          ) : (
            expertSingles.slice(0, 3).map((tip) => {
              const {
                awayTeam,
                homeTeam,
                awayTeamLogo,
                homeTeamLogo,
                competitionLogo,
                _id,
                matchTime,
              } = tip;
              return (
                <ExpertSingle
                  key={_id}
                  awayTeam={awayTeam}
                  awayTeamLogo={awayTeamLogo}
                  competitionLogo={competitionLogo}
                  homeTeam={homeTeam}
                  homeTeamLogo={homeTeamLogo}
                  id={_id}
                  matchTime={matchTime}
                />
              );
            })
          )}
        </div>
      )}

      <div className='btn'>
        <button className='blue' onClick={() => navigate(`/expert-singles`)}>
          View All <BsArrowRight />
        </button>
      </div>
    </section>
  );
};

export default FreeExpertSingles;
