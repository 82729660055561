import React from 'react';
import { useNavigate } from 'react-router-dom';

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <main className='error-page'>
        <h1>Page Not Found</h1>
        <button className='blue' onClick={() => navigate(`/`)}>
          Return Home
        </button>
      </main>
    </>
  );
};

export default ErrorPage;
