import React, { useEffect, useState } from "react";
import { MainNewsCard, SubNewsCard } from "../News-Card/NewsCard";
import { useGlobalContext } from "../../context/context";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { NewsUpdatesComponentLoader } from "../loaders/Loaders";

const NewsUpdates = () => {
  const [news, setNews] = useState([]);
  const { baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const getAllNews = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/news?limit=5`);
      setLoading(false);
      setNews(data.news);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllNews();
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <NewsUpdatesComponentLoader />;
  }

  if (!loading && news.length < 1) {
    return <NewsUpdatesComponentLoader />;
  }

  return (
    <section className="news-updates">
      {/* MOBILE ARRANGEMENT */}
      <div className="mobile">
        <h2 className="title">News Updates</h2>
        <div className="news">
          {/* main news */}
          <MainNewsCard
            sportCategory={news[0].sportCategory}
            timePosted={news[0].createdAt}
            title={news[0].title}
            content={news[0].content}
            image={news[0].image}
            id={news[0]._id}
          />

          {/* sub news */}
          <div className="sub-news-grid">
            {news.slice(1).map((news) => {
              const { sportCategory, createdAt, title, image, _id } = news;
              return (
                <SubNewsCard
                  sportCategory={sportCategory}
                  timePosted={createdAt}
                  title={title}
                  image={image}
                  id={_id}
                  key={_id}
                />
              );
            })}
          </div>
        </div>
        <div className="btn">
          <button className="blue" onClick={() => navigate(`/news-updates`)}>
            Read More
          </button>
        </div>
      </div>
      {/* DESKTOP ARRANGMENT */}
      <div className="desktop">
        <div className="top-story">
          <h2>Top Story</h2>
          <MainNewsCard
            sportCategory={news[0].sportCategory}
            timePosted={news[0].createdAt}
            title={news[0].title}
            image={news[0].image}
            content={news[0].content}
            id={news[0]._id}
          />
        </div>

        <div className="subs">
          <div className="headers">
            <h2>News Updates</h2>
            <button className="blue" onClick={() => navigate(`/news-updates`)}>
              Read More
            </button>
          </div>
          <div className="grid">
            {news.slice(1, 5).map((news) => {
              const { sportCategory, createdAt, title, image, _id } = news;
              return (
                <SubNewsCard
                  sportCategory={sportCategory}
                  timePosted={createdAt}
                  title={title}
                  image={image}
                  id={_id}
                  key={_id}
                />
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewsUpdates;
