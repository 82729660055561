import React, { useEffect, useState } from 'react';
import { FaTelegram, FaInstagram, FaTwitter, FaWhatsapp } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useGlobalContext } from '../../context/context';
import axios from 'axios';

const Footer = () => {
  const [facebookLink, setFacebookLink] = useState(``);
  const [telegramLink, setTelegramLink] = useState(``);
  const [instagramLink, setInstagramLink] = useState(``);
  const [twitterLink, setTwitterLink] = useState(``);
  const { baseURL, predict4MeLink } = useGlobalContext();

  // FETCH SOCIAL MEDIA LINKS
  const fetchSocialMediaLinks = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/social-media-links`);

      // facebook has been changed to whatsapp. Take note!!!!!
      setFacebookLink(
        data.links.filter((link) => link.name === `facebook`)[0].link
      );
      setTelegramLink(
        data.links.filter((link) => link.name === `telegram`)[0].link
      );
      setInstagramLink(
        data.links.filter((link) => link.name === `instagram`)[0].link
      );
      setTwitterLink(
        data.links.filter((link) => link.name === `twitter`)[0].link
      );
    } catch (error) {}
  };

  useEffect(() => {
    fetchSocialMediaLinks();
    // eslint-disable-next-line
  }, []);

  return (
    <footer>
      <Link to='/' className='logo'></Link>
      <div className='grid'>
        <div className='item'>
          <h3>Company</h3>
          <ul>
            <li>
              <Link to='/about-us'>About Us</Link>
            </li>
            <li>
              <Link to='/news-updates'>News Updates</Link>
            </li>
            <li>
              <Link to='/contact-us'>Contact Us</Link>
            </li>
          </ul>
        </div>
        <div className='item'>
          <h3>Quick Links</h3>
          <ul>
            <li>
              <Link to='/league-standings'>League Standing</Link>
            </li>
            <li>
              <Link to='/offers'>Offers</Link>
            </li>
            <li>
              <Link to='/expert-singles'>Expert Singles</Link>
            </li>
            <li>
              <a href={predict4MeLink} target='_blank' rel='noreferrer'>
                Predict 4 Me 🔥
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className='copyright-socials'>
        <div className='socials'>
          <a href={telegramLink} target='_blank' rel='noreferrer'>
            <FaTelegram />
          </a>
          <a href={instagramLink} target='_blank' rel='noreferrer'>
            <FaInstagram />
          </a>
          <a href={twitterLink} target='_blank' rel='noreferrer'>
            <FaTwitter />
          </a>
          <a href={facebookLink} target='_blank' rel='noreferrer'>
            <FaWhatsapp />
          </a>
        </div>
        <p className='copyright'>© 2023 KM Analysis. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
