import React, { useEffect, useState } from 'react';
import AdTelegram from '../../components/ad-telegram/AdTelegram';
import FreeBetsOptions from '../../components/free-bets-options/FreeBetsOptions';
import { useGlobalContext } from '../../context/context';
import axios from 'axios';
import spinner from '../../assets/rolling-spinner-primary.svg';

const Offers = () => {
  const { baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(true);
  const [offers, setOffers] = useState([]);

  const getOffers = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${baseURL}/offers`);
      setOffers(data.offers);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getOffers();
    // eslint-disable-next-line
  }, []);

  return (
    <main className='offers-page'>
      <div className='options'>
        <FreeBetsOptions itemBgColor={`#509eff`} />
      </div>
      <h1 className='title'>Exclusive Offers 🔥</h1>

      {/*OFFERS */}
      <section className='offers'>
        {loading ? (
          <div className='loader'>
            <img src={spinner} alt='' />
          </div>
        ) : (
          <>
            {offers.length > 0 ? (
              <>
                <div className='grid'>
                  {offers.map((offer, offerIndex) => {
                    const { link, image, caption } = offer;
                    return (
                      <a
                        href={link}
                        target='_blank'
                        rel='noreferrer'
                        className='item'
                        key={offerIndex}
                      >
                        <img
                          src={`http://api.kmanalysis.com/${image}`}
                          alt=''
                        />
                        <p>{caption}</p>
                      </a>
                    );
                  })}
                </div>
              </>
            ) : (
              <div className='currently-unavailable'>
                <h3>Currently Unavailable</h3>
              </div>
            )}
          </>
        )}
      </section>

      <AdTelegram />
    </main>
  );
};

export default Offers;
