import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useGlobalContext } from '../../context/context';
import spinner from '../../assets/rolling-spinner-white.svg';
import MetaDecorator from '../../components/MetaDecorator/MetaDecorator';

const ContactUs = () => {
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const { baseURL } = useGlobalContext();

  const formik = useFormik({
    initialValues: {
      fname: ``,
      lname: ``,
      email: ``,
      message: ``,
    },
    validationSchema: Yup.object({
      email: Yup.string().email(`Invalid Email`).required(`Email is required`),
      fname: Yup.string().required(`First name is required`),
      lname: Yup.string().required(`Last name is required`),
      message: Yup.string().required(`Message is required`),
    }),
    onSubmit() {
      sendMessage();
    },
  });

  // FUNCTION TO SEND MESSAGE
  const sendMessage = async () => {
    const { email, fname, lname, message } = formik.values;
    try {
      setLoading(true);
      const { data } = await axios.post(`${baseURL}/contact-us`, {
        name: `${fname.toLowerCase()} ${lname.toLowerCase()}`,
        email: email.toLowerCase(),
        phone: phone,
        message: message,
      });
      setLoading(false);
      toast.success(data.msg);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <>
      <MetaDecorator
        title={'Contact Us - KManalysis'}
        description={`Get in touch with KManalysis for expert soccer analysis.`}
      />
      <main className='contact-us-page'>
        <div className='title-block'>
          <h4>Contact us</h4>
          <h1>Get in touch</h1>
          <p>We'd love to hear from you. Please fill out this form.</p>
        </div>

        {/*  FORM*/}
        <form action='' onSubmit={formik.handleSubmit}>
          {/* FIRST NAME */}
          <div className='form-control'>
            <label
              htmlFor='fname'
              className={
                formik.touched.fname && formik.errors.fname ? `error` : null
              }
            >
              {formik.touched.fname && formik.errors.fname
                ? formik.errors.fname
                : `First Name`}
            </label>
            <input
              type='text'
              placeholder='First name'
              id='fname'
              name='fname'
              value={formik.values.fname}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          {/* LAST NAME */}
          <div className='form-control'>
            <label
              htmlFor='lname'
              className={
                formik.touched.lname && formik.errors.lname ? `error` : null
              }
            >
              {formik.touched.lname && formik.errors.lname
                ? formik.errors.lname
                : `Last Name`}
            </label>
            <input
              type='text'
              placeholder='Last name'
              id='lname'
              name='lname'
              value={formik.values.lname}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          {/* EMAIL */}
          <div className='form-control'>
            <label
              htmlFor='email'
              className={
                formik.touched.email && formik.errors.email ? `error` : null
              }
            >
              {formik.touched.email && formik.errors.email
                ? formik.errors.email
                : `Email`}
            </label>
            <input
              type='email'
              placeholder='example@gmail.com'
              id='email'
              name='email'
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          {/* PHONE NUMBER */}
          <div className='form-control'>
            <label
              htmlFor='phone'
              className={
                formik.touched.phoneNumber && formik.errors.phoneNumber
                  ? `error`
                  : ``
              }
            >
              {formik.touched.phoneNumber && formik.errors.phoneNumber
                ? formik.errors.phoneNumber
                : `Phone Number`}
            </label>
            <PhoneInput
              inputProps={{
                name: 'phone',
                required: true,
              }}
              country={'us'}
              value={phone}
              onChange={(newPhone) => setPhone(newPhone)}
              containerClass='phone-input'
              inputClass='input'
              buttonClass='btn'
            />
          </div>

          {/* MESSAGE */}
          <div className='form-control'>
            <label
              htmlFor='message'
              className={
                formik.touched.message && formik.errors.message ? `error` : null
              }
            >
              {formik.touched.message && formik.errors.message
                ? formik.errors.message
                : `Message`}
            </label>
            <textarea
              name='message'
              id='message'
              value={formik.values.message}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            ></textarea>
          </div>

          {/* PRIVACY POLICY */}
          <div className='form-control checkbox'>
            <input type='checkbox' required />
            <label htmlFor='privacy-policy'>
              You agree to our friendly <Link>privacy policy</Link>.
            </label>
          </div>

          {/* SUBMIT BTN */}
          <div className='submit-btn'>
            <button className='dark-blue' type='submit'>
              Send message {loading ? <img src={spinner} alt='' /> : null}
            </button>
          </div>
        </form>

        {/* CHAT WITH OUR TEAM */}
        <section className='chat-with-our-team'>
          <div className='title'>
            <h2>Chat to our friendly team</h2>
            <p>We'd love to hear from you! Please get in touch.</p>
          </div>
          <div className='methods'>
            <div className='block'>
              <h3>Contact</h3>
              <a href='mailto:contact@kmanalysis.com'>contact@kmanalysis.com</a>
            </div>
            <div className='block'>
              <h3>Support</h3>
              <a href='mailto:support@kmanalysis.com'>support@kmanalysis.com</a>
            </div>
            <div className='block'>
              <h3>Marketing</h3>
              <a href='mailto:marketing@kmanalysis.com'>
                marketing@kmanalysis.com
              </a>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default ContactUs;
