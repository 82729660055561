import React, { useEffect, useState } from 'react';
import FreeBetsOptions from '../../components/free-bets-options/FreeBetsOptions';
import AdTelegram from '../../components/ad-telegram/AdTelegram';
import FreeExpertSingles from '../../components/free-expert-singles/FreeExpertSingles';
import ExpertSingleComp from '../../components/expert-single/ExpertSingle';
import { useGlobalContext } from '../../context/context';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { PageLoader } from '../../components/loaders/Loaders';
import oneXBetLogo from '../../assets/1xbet-logo-2.png';
import betanoLogo from '../../assets/betano_logo.svg';
import betwinnerLogo from '../../assets/betwinner-logo.svg';
import zebetLogo from '../../assets/zebet-logo.png';
import sportybetLogo from '../../assets/sportybet-logo.jpg';
import bet9jaLogo from '../../assets/bet9ja-logo.png';
import twentytwoBetLogo from '../../assets/22bet-logo.png';
import paripesaLogo from '../../assets/paripesa-logo.jpg';
import { Link } from 'react-router-dom';

import { TbChevronsRight } from 'react-icons/tb';
import { FaCopy } from 'react-icons/fa';

const ExpertSingle = () => {
  const [expertSingle, setExpertSingle] = useState([]);
  const [loading, setLoading] = useState(true);
  const { baseURL } = useGlobalContext();
  const { id } = useParams();
  const userToken = JSON.parse(sessionStorage.getItem(`userToken`));

  const [copyCode, setCopyCode] = useState(false);

  const getExpertSingle = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/expert-singles-tips/${id}`);
      setLoading(false);
      setExpertSingle(data.tip);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getExpertSingle();
    // eslint-disable-next-line
  }, [id]);

  const {
    awayTeam,
    awayTeamAbrv,
    homeTeam,
    homeTeamAbrv,
    awayTeamLogo,
    homeTeamLogo,
    competitionLogo,
    _id,
    matchTime,
    awayTeamPrevMatches,
    homeTeamPrevMatches,
    prediction,
    bookies,
  } = expertSingle;

  return (
    <main className='expert-single-page'>
      <div className='options'>
        <FreeBetsOptions itemBgColor={`#509eff`} />
      </div>

      {loading ? (
        <PageLoader />
      ) : (
        <>
          {/* GAME */}
          <div className='game'>
            <ExpertSingleComp
              key={_id}
              awayTeam={awayTeam}
              awayTeamLogo={awayTeamLogo}
              competitionLogo={competitionLogo}
              homeTeam={homeTeam}
              homeTeamLogo={homeTeamLogo}
              id={_id}
              matchTime={matchTime}
            />
          </div>

          {/* PREVIOUS MATCHES */}
          <section className='previous-matches'>
            <h3>Previous Matches</h3>
            <div className='teams'>
              <div className='team'>
                <div className='club'>
                  <img src={homeTeamLogo} alt={homeTeam} />
                  <span>{homeTeamAbrv}</span>
                </div>
                <div className='stats'>
                  {homeTeamPrevMatches.map((stat, statIndex) => {
                    return (
                      <p
                        className={
                          stat === `w`
                            ? `win`
                            : stat === `l`
                            ? `lose`
                            : stat === `d`
                            ? `draw`
                            : ``
                        }
                        key={statIndex}
                      >
                        {stat}
                      </p>
                    );
                  })}
                </div>
              </div>
              <div className='team'>
                <div className='club'>
                  <img src={awayTeamLogo} alt={awayTeam} />
                  <span>{awayTeamAbrv}</span>
                </div>
                <div className='stats'>
                  {awayTeamPrevMatches.map((stat, statIndex) => {
                    return (
                      <p
                        className={
                          stat === `w`
                            ? `win`
                            : stat === `l`
                            ? `lose`
                            : stat === `d`
                            ? `draw`
                            : ``
                        }
                        key={statIndex}
                      >
                        {stat}
                      </p>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>

          {/* OUR PREDICTION */}
          <section className='our-prediction'>
            <p dangerouslySetInnerHTML={{ __html: prediction }}></p>
          </section>

          {/* BOOKING CODES */}
          {bookies.length > 0 && (
            <section className='booking-codes'>
             <h2>
        {userToken ? (
          'BOOKING CODES FOR THIS PREDICTION'
        ) : (
          <>
            PLEASE <Link to="/sign-in" className='g-text'>SIGN-IN</Link> TO VIEW THE BOOKING CODES FOR THIS PREDICTION
          </>
        )}
      </h2>
              {userToken && (
                <div className='codes'>
                  {copyCode ? <span className='copied'>Copied!</span> : null}
                  {bookies.map((bookie) => {
                    const { name, link, code, _id } = bookie;
                    return (
                      <div className='bookie' key={_id}>
                        <div className='img'>
                          {name === `zebet` ? (
                            <img src={zebetLogo} alt='zebet' />
                          ) : name === `1xbet` ? (
                            <img src={oneXBetLogo} alt='1xbet' />
                          ) : name === `betano` ? (
                            <img src={betanoLogo} alt='betano' />
                          ) : name === `betwinner` ? (
                            <img src={betwinnerLogo} alt='betwinner' />
                          ) : name === `sportybet` ? (
                            <img src={sportybetLogo} alt='sportybet' />
                          ) : name === `bet9ja` ? (
                            <img src={bet9jaLogo} alt='bet9ja' />
                          ) : name === `22bet` ? (
                            <img src={twentytwoBetLogo} alt='22bet' />
                          ) : name === `paripesa` ? (
                            <img src={paripesaLogo} alt='paripesa' />
                          ) : null}
                        </div>
                        <div
                          className='code'
                          onClick={() => {
                            navigator.clipboard.writeText(code);
                            setCopyCode(true);
                            setTimeout(() => {
                              setCopyCode(false);
                            }, 1500);
                          }}
                        >
                          <div className='info'>
                            <h3>{code}</h3>
                            <FaCopy />
                          </div>
                        </div>
                        <a
                          href={link}
                          target='_blank'
                          rel='noreferrer'
                          className='link'
                        >
                          <button>
                            BET HERE <TbChevronsRight />
                          </button>
                        </a>
                      </div>
                    );
                  })}
                </div>
              )}
            </section>
          )}
        </>
      )}

      <FreeExpertSingles />
      <AdTelegram />
    </main>
  );
};

export default ExpertSingle;
