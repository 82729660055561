import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const baseURL = `https://api.kmanalysis.com/api/v1`;
  // const baseURL = `http://localhost:5000/api/v1`;
  const [showNav, setShowNav] = useState(false);
  const [showFlutterwaveModal, setShowFlutterwaveModal] = useState(false);
  const [freeTipsOption, setFreeTipsOption] = useState(() => {
    return localStorage.getItem(`bettingOption`)
      ? JSON.parse(localStorage.getItem(`bettingOption`))
      : ``;
  });

  // FUNCTION TO REVEAL PASSWORD
  const revealPassword = () => {
    const passwordInput = document.querySelector(`#password`);
    if (passwordInput.type === "password") {
      passwordInput.type = "text";
    } else {
      passwordInput.type = "password";
    }
  };

  // GET UPDATED USER DATA
  const getUser = async (token) => {
    try {
      const { data } = await axios.get(`${baseURL}/users/user-info`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      sessionStorage.setItem(`userInfo`, JSON.stringify(data));
    } catch (error) {
      console.log(error.response.data.msg);
    }
  };

  // FETCH TELEGRAM LINK
  const [telegramLink, setTelegramLink] = useState(``);
  const fetchSocialMediaLinks = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/social-media-links`);

      setTelegramLink(
        data.links.filter((link) => link.name === `telegram`)[0].link
      );
    } catch (error) {}
  };

  // FETCH ADS
  const [adOne, setAdOne] = useState(``);
  const [adTwo, setAdTwo] = useState(``);

  const fwtchAds = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/ads`);
      setAdOne(data[0]);
      setAdTwo(data[1]);
    } catch (error) {
      console.log(error);
    }
  };

  // FETCH PREDICT4ME LINK
  const [predict4MeLink, setPredict4MeLink] = useState(``);

  const fetchPredict4MeLink = useCallback(async () => {
    try {
      const { data } = await axios.get(`${baseURL}/predict-4-me`);
      setPredict4MeLink(data.link);
    } catch (error) {
      console.log(error);
    }
  }, [baseURL]);

  useEffect(() => {
    fetchPredict4MeLink();
  }, [fetchPredict4MeLink]);

  useEffect(() => {
    fetchSocialMediaLinks();
    fwtchAds();
    fetchPredict4MeLink();
    // eslint-disable-next-line
  }, []);

  return (
    <AppContext.Provider
      value={{
        baseURL,
        showNav,
        setShowNav,
        revealPassword,
        freeTipsOption,
        setFreeTipsOption,
        showFlutterwaveModal,
        setShowFlutterwaveModal,
        getUser,
        telegramLink,
        adOne,
        adTwo,
        predict4MeLink,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useGlobalContext = () => {
  return useContext(AppContext);
};

export { useGlobalContext, AppProvider };
