import Skeleton from 'react-loading-skeleton';
import spinner from '../../assets/rolling-spinner-primary.svg';
import freeExpertSinglesVS from '../../assets/free-expert-singles-vs.png';

export const PageLoader = () => {
  return (
    <main className='page-loader'>
      <img src={spinner} alt='' />
    </main>
  );
};

export const NewsUpdatesComponentLoader = () => {
  return (
    <main className='news-updates-component-loader'>
      <img src={spinner} alt='' />
    </main>
  );
};

export const AccumulatorTipSkeleton = ({ cards }) => {
  return (
    <>
      {Array(cards)
        .fill(0)
        .map((_, index) => {
          return (
            <div className='accumulator-tip-skeleton' key={index}>
              <div className='kick-off'>
                <h5>Kick Off</h5>
                <Skeleton className='item' />
              </div>
              <div className='code-bookie'>
                <Skeleton className='item' />
                <Skeleton className='item' />
              </div>
              <div className='total-odds'>
                <h5>Total Odds</h5>
                <Skeleton className='item' />
              </div>
            </div>
          );
        })}
    </>
  );
};

export const ExpertSingleTipSkeleton = ({ cards }) => {
  return (
    <>
      {Array(cards)
        .fill(0)
        .map((_, index) => {
          return (
            <div className='expert-single-skeleton' key={index}>
              <div className='competition'>
                <Skeleton height={50} />
              </div>
              <div className='teams'>
                <div className='team'>
                  <Skeleton height={40} />
                </div>
                <div className='vs'>
                  <img src={freeExpertSinglesVS} alt='vs' />
                </div>
                <div className='team'>
                  <Skeleton height={40} />
                </div>
              </div>
              <div className='time'>
                <h4>
                  <Skeleton height={30} style={{ marginBottom: `1rem` }} />
                </h4>
                <p>
                  <Skeleton />
                </p>
              </div>
            </div>
          );
        })}
    </>
  );
};

export const TelegramAdSkeleton = () => {
  return (
    <div className='telegram-ad-skeleton'>
      <Skeleton className='item' />
    </div>
  );
};
