import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { useGlobalContext } from '../../context/context';
import { Link, useNavigate } from 'react-router-dom';
import { RxHamburgerMenu } from 'react-icons/rx';

const Navbar = ({ activePage }) => {
  const { showNav, setShowNav, predict4MeLink } = useGlobalContext();
  const userToken = JSON.parse(sessionStorage.getItem(`userToken`));
  const navigate = useNavigate();

  const closeNav = () => {
    setShowNav(false);
  };

  return (
    <nav className='navbar'>
      <Link to='/' className='logo' onClick={closeNav}></Link>

      {/* NAV LINKS  */}
      <div className={`links ${showNav ? `show` : null}`}>
        <ul>
          <li onClick={closeNav} className={activePage === `/` ? `active` : ``}>
            <Link to='/'>Home</Link>
          </li>
          <li
            onClick={closeNav}
            className={activePage === `/free-accumulator-tips` ? `active` : ``}
          >
            <Link to='/free-accumulator-tips'>Free Accumulators</Link>
          </li>
          <li
            onClick={closeNav}
            className={activePage.includes(`/expert-singles`) ? `active` : ``}
          >
            <Link to='/expert-singles'>Expert Singles</Link>
          </li>
          <li
            onClick={closeNav}
            className={
              activePage === `/news-updates` ||
              activePage.includes(`/news-updates/`)
                ? `active`
                : ``
            }
          >
            <Link to='/news-updates'>Sport News</Link>
          </li>
          <li
            onClick={closeNav}
            className={activePage === `League Standings` ? `active` : ``}
          >
            <Link to='/league-standings'>League Standings</Link>
          </li>
          <li
            onClick={closeNav}
            className={activePage === `Predict 4 Me` ? `active` : ``}
          >
            <a href={predict4MeLink} target='_blank' rel='noreferrer'>
              Predict 4 Me 🔥
            </a>
          </li>
        </ul>
        <div className='auth-btns'>
          {userToken ? (
            <button
              className='red'
              onClick={() => {
                sessionStorage.removeItem(`userToken`);
                navigate(`/`);
              }}
            >
              Sign Out
            </button>
          ) : (
            <>
              <button
                className='blue'
                onClick={() => {
                  navigate(`/sign-in`);
                  closeNav();
                }}
              >
                Sign In
              </button>
              <button
                className='sblue-button'
                onClick={() => {
                  navigate(`/sign-up`);
                  closeNav();
                }}
              >
                Sign Up
              </button>
            </>
          )}
        </div>
      </div>

      <div className='sign-in-hamburger-close'>
        {userToken ? (
          <button
            className='red'
            onClick={() => {
              sessionStorage.removeItem(`userToken`);
              navigate(`/`);
              closeNav();
            }}
          >
            Sign Out
          </button>
        ) : (
          <>
            <button
              className='blue'
              onClick={() => {
                navigate(`/sign-in`);
                closeNav();
              }}
            >
              Sign In
            </button>
          </>
        )}
        {showNav ? (
          <AiOutlineClose
            className={showNav ? `active` : ``}
            onClick={() => setShowNav(false)}
          />
        ) : (
          <RxHamburgerMenu
            className={showNav ? `` : `active`}
            onClick={() => setShowNav(true)}
          />
        )}
      </div>
    </nav>
  );
};

export default Navbar;
