import React, { useState } from 'react';
import AdTelegram from '../../components/ad-telegram/AdTelegram';
import { useGlobalContext } from '../../context/context';

const LeagueStandings = () => {
  const { adTwo } = useGlobalContext();
  const [league, setLeague] = useState(`Premier League`);

  console.log(league);

  return (
    <main className='league-standings-page'>
      {/* AD SPACE */}
      {adTwo.adLink?.toLowerCase() === `no ad` ? null : (
        <article className='ad'>
          <a href={adTwo.adLink} target='_blank' rel='noreferrer'>
            <img
              src={`https://api.kmanalysis.com/${adTwo.adImage}`}
              alt={adTwo.adCaption}
            />
            <h3 className='caption'>{adTwo.adCaption}</h3>
          </a>
        </article>
      )}

      <div className='select-league'>
        <select
          name='league'
          id='league'
          onChange={(e) => setLeague(e.target.value)}
        >
          <option value='Premier League' defaultChecked>
            Premier League
          </option>
          <option value='La Liga' defaultChecked>
            La Liga
          </option>
          <option value='Bundesliga' defaultChecked>
            Bundesliga
          </option>
          <option value='Ligue 1' defaultChecked>
            Ligue 1
          </option>
          <option value='Serie A' defaultChecked>
            Serie A
          </option>
          <option value='Netherlands (Eredivisie)' defaultChecked>
            Netherlands (Eredivisie)
          </option>
          <option value='Portugal (Premiera Liga)' defaultChecked>
            Portugal (Premiera Liga)
          </option>
        </select>
      </div>

      <section className='standings'>
        {league === `Premier League` ? (
          <iframe
            title='premier league'
            frameborder='0'
            scrolling='no'
            width='520'
            height='700'
            src='https://www.fctables.com/england/premier-league/iframe/?type=table&lang_id=2&country=67&template=10&team=&timezone=Africa/Lagos&time=24&po=0&ma=1&wi=1&dr=1&los=1&gf=0&ga=0&gd=0&pts=1&ng=0&form=1&width=520&height=700&font=Helvetica&fs=12&lh=28&bg=ffffff&fc=000000&logo=1&tlink=0&ths=1&thb=1&thba=FFFFFF&thc=000000&bc=ffffff&hob=f5f5f5&hobc=ebe7e7&lc=333333&sh=1&hfb=1&hbc=224099&hfc=FFFFFF'
          ></iframe>
        ) : league === `La Liga` ? (
          <iframe
            title='la liga'
            frameborder='0'
            scrolling='no'
            width='520'
            height='700'
            src='https://www.fctables.com/spain/liga-bbva/iframe/?type=table&lang_id=2&country=201&template=43&timezone=Africa/Lagos&time=24&po=0&ma=1&wi=1&dr=1&los=1&gf=0&ga=0&gd=0&pts=1&ng=0&form=1&width=520&height=700&font=Helvetica&fs=12&lh=28&bg=ffffff&fc=000000&logo=1&tlink=0&scfs=22&scfc=333333&scb=1&sclg=1&teamls=80&ths=1&thb=1&thba=FFFFFF&thc=000000&bc=ffffff&hob=f5f5f5&hobc=ebe7e7&lc=333333&sh=1&hfb=1&hbc=224099&hfc=FFFFFF'
          ></iframe>
        ) : league === `Bundesliga` ? (
          <iframe
            title='bundesliga'
            frameborder='0'
            scrolling='no'
            width='520'
            height='700'
            src='https://www.fctables.com/germany/1-bundesliga/iframe/?type=table&lang_id=2&country=83&template=16&timezone=Africa/Lagos&time=24&po=0&ma=1&wi=1&dr=1&los=1&gf=0&ga=0&gd=0&pts=1&ng=0&form=1&width=520&height=700&font=Helvetica&fs=12&lh=28&bg=ffffff&fc=000000&logo=1&tlink=0&scfs=22&scfc=333333&scb=1&sclg=1&teamls=80&ths=1&thb=1&thba=FFFFFF&thc=000000&bc=ffffff&hob=f5f5f5&hobc=ebe7e7&lc=333333&sh=1&hfb=1&hbc=224099&hfc=FFFFFF'
          ></iframe>
        ) : league === `Ligue 1` ? (
          <iframe
            title='ligue 1'
            frameborder='0'
            scrolling='no'
            width='520'
            height='700'
            src='https://www.fctables.com/france/ligue-1/iframe/?type=table&lang_id=2&country=77&template=15&team=&timezone=Africa/Lagos&time=24&po=0&ma=1&wi=1&dr=1&los=1&gf=0&ga=0&gd=0&pts=1&ng=0&form=1&width=520&height=700&font=Helvetica&fs=12&lh=28&bg=ffffff&fc=000000&logo=1&tlink=0&scfs=22&scfc=333333&scb=1&sclg=1&teamls=80&ths=1&thb=1&thba=FFFFFF&thc=000000&bc=ffffff&hob=f5f5f5&hobc=ebe7e7&lc=333333&sh=1&hfb=1&hbc=224099&hfc=FFFFFF'
          ></iframe>
        ) : league === `Serie A` ? (
          <iframe
            title='seria a'
            frameborder='0'
            scrolling='no'
            width='520'
            height='700'
            src='https://www.fctables.com/italy/serie-a/iframe/?type=table&lang_id=2&country=108&template=17&timezone=Africa/Lagos&time=24&po=0&ma=1&wi=1&dr=1&los=1&gf=0&ga=0&gd=0&pts=1&ng=0&form=1&width=520&height=700&font=Helvetica&fs=12&lh=28&bg=ffffff&fc=000000&logo=1&tlink=0&scfs=22&scfc=333333&scb=1&sclg=1&teamls=80&ths=1&thb=1&thba=FFFFFF&thc=000000&bc=ffffff&hob=f5f5f5&hobc=ebe7e7&lc=333333&sh=1&hfb=1&hbc=224099&hfc=FFFFFF'
          ></iframe>
        ) : league === `Netherlands (Eredivisie)` ? (
          <iframe
            title='netherlands'
            frameborder='0'
            scrolling='no'
            width='520'
            height='700'
            src='https://www.fctables.com/netherlands/eredivisie/iframe/?type=table&lang_id=2&country=155&template=19&timezone=Africa/Lagos&time=24&po=0&ma=1&wi=1&dr=1&los=1&gf=0&ga=0&gd=0&pts=1&ng=0&form=1&width=520&height=700&font=Helvetica&fs=12&lh=28&bg=ffffff&fc=000000&logo=1&tlink=0&scfs=22&scfc=333333&scb=1&sclg=1&teamls=80&ths=1&thb=1&thba=FFFFFF&thc=000000&bc=ffffff&hob=f5f5f5&hobc=ebe7e7&lc=333333&sh=1&hfb=1&hbc=224099&hfc=FFFFFF'
          ></iframe>
        ) : league === `Portugal (Premiera Liga)` ? (
          <iframe
            title='portugal'
            frameborder='0'
            scrolling='no'
            width='520'
            height='700'
            src='https://www.fctables.com/portugal/liga-zon-sagres/iframe/?type=table&lang_id=2&country=175&template=23&timezone=Africa/Lagos&time=24&po=0&ma=1&wi=1&dr=1&los=1&gf=0&ga=0&gd=0&pts=1&ng=0&form=1&width=520&height=700&font=Helvetica&fs=12&lh=28&bg=ffffff&fc=000000&logo=1&tlink=0&scfs=22&scfc=333333&scb=1&sclg=1&teamls=80&ths=1&thb=1&thba=FFFFFF&thc=000000&bc=ffffff&hob=f5f5f5&hobc=ebe7e7&lc=333333&sh=1&hfb=1&hbc=224099&hfc=FFFFFF'
          ></iframe>
        ) : null}
      </section>

      <AdTelegram />
    </main>
  );
};

export default LeagueStandings;
