import React, { useEffect, useState } from 'react';
import AdTelegram from '../../components/ad-telegram/AdTelegram';
import { Link } from 'react-router-dom';
import FreeBetsOptions from '../../components/free-bets-options/FreeBetsOptions';
import { FreeRegularTip } from '../../components/predictions/Predictions';
import { useGlobalContext } from '../../context/context';
import axios from 'axios';
import moment from 'moment';
import spinner from '../../assets/rolling-spinner-primary.svg';
import { BsFlagFill } from 'react-icons/bs';

const OverOnePointFive = () => {
  const { baseURL, adTwo, predict4MeLink } = useGlobalContext();
  const [loading, setLoading] = useState(true);
  const [currentTips, setCurrentTips] = useState(`today`);
  const [tips, setTips] = useState([]);

  const getOverOnePointFiveTips = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${baseURL}/over-1.5-tips`);

      if (currentTips === `today`) {
        setTips(
          data.tips.filter(
            (tip) =>
              moment(tip.matchTime).format(`ll`) === moment().get().format(`ll`)
          )
        );
      } else if (currentTips === `tomorrow`) {
        setTips(
          data.tips.filter(
            (tip) =>
              moment(tip.matchTime).format(`ll`) ===
              moment().get().add(1, `day`).format(`ll`)
          )
        );
      } else if (currentTips === `yesterday`) {
        setTips(
          data.tips.filter(
            (tip) =>
              moment(tip.matchTime).format(`ll`) ===
              moment().get().subtract(1, `day`).format(`ll`)
          )
        );
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getOverOnePointFiveTips();
    // eslint-disable-next-line
  }, [currentTips]);

  const handleClick = (e) => {
    setCurrentTips(e.currentTarget.textContent.toLowerCase());
  };

  return (
    <main className='over-one-point-five-page general-free-tips-page'>
      <div className='options'>
        <FreeBetsOptions itemBgColor={`#509eff`} />
      </div>
      <h1 className='title'>Over 1.5 Predictions</h1>

      {/* AD SPACE */}
      {adTwo.adLink?.toLowerCase() === `no ad` ? null : (
        <article className='ad'>
          <a href={adTwo.adLink} target='_blank' rel='noreferrer'>
            <img
              src={`https://api.kmanalysis.com/${adTwo.adImage}`}
              alt={adTwo.adCaption}
            />
            <h3 className='caption'>{adTwo.adCaption}</h3>
          </a>
        </article>
      )}

      {/*GAMES */}
      <section className='games'>
        {/* mobile arrangment */}
        <div className='mobile'>
          <ul className='tabs'>
            <li
              className={currentTips === `yesterday` ? `active` : ``}
              onClick={handleClick}
            >
              Yesterday
            </li>
            <li
              className={currentTips === `today` ? `active` : ``}
              onClick={handleClick}
            >
              Today
            </li>
            <li
              className={currentTips === `tomorrow` ? `active` : ``}
              onClick={handleClick}
            >
              Tomorrow
            </li>
          </ul>

          {loading ? (
            <div className='loader'>
              <img src={spinner} alt='' />
            </div>
          ) : (
            <div className='predictions'>
              <div className='container'>
                <div className='table-head'>
                  <p>
                    <BsFlagFill />
                  </p>
                  <p>Match</p>
                  <p className='tip'>Tip</p>
                  <p>Time</p>
                  <p>Result</p>
                  <p>Status</p>
                </div>
                {tips.length > 0 ? (
                  <>
                    {tips.map((tip, tipIndex) => {
                      const {
                        matchTime,
                        homeTeam,
                        awayTeam,
                        league,
                        status,
                        result,
                        prediction,
                      } = tip;

                      return (
                        <FreeRegularTip
                          awayTeam={awayTeam}
                          homeTeam={homeTeam}
                          matchTime={moment(matchTime).format(`HH:mm`)}
                          status={status}
                          league={league}
                          key={tipIndex}
                          index={tipIndex}
                          extraClass={`over-bttsgg`}
                          result={result}
                          prediction={prediction}
                        />
                      );
                    })}
                  </>
                ) : (
                  <div className='currently-unavailable'>
                    <h3>Currently Unavailable</h3>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        {/* desktop arrangment */}
        <div className='desktop'>
          <div className='table-head'>
            <p>
              <BsFlagFill />
            </p>
            <p>Match</p>
            <p className='tip'>Tip</p>
            <p>Time</p>
            <p>Result</p>
            <p>Status</p>
          </div>
          <div className='item'>
            {loading ? (
              <div className='loader'>
                <img src={spinner} alt='' />
              </div>
            ) : (
              <>
                {tips.length > 0 ? (
                  <>
                    <ul>
                      {tips.map((tip, tipIndex) => {
                        const {
                          matchTime,
                          homeTeam,
                          awayTeam,
                          league,
                          status,
                          result,
                          prediction,
                        } = tip;

                        return (
                          <li key={tipIndex}>
                            <FreeRegularTip
                              awayTeam={awayTeam}
                              homeTeam={homeTeam}
                              matchTime={moment(matchTime).format(`HH:mm`)}
                              status={status}
                              league={league}
                              index={tipIndex}
                              extraClass={`over-bttsgg`}
                              result={result}
                              prediction={prediction}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ) : (
                  <div className='currently-unavailable'>
                    <h3>Currently Unavailable</h3>
                  </div>
                )}
              </>
            )}
          </div>
          <div className='btns'>
            <button className='dark-blue' onClick={handleClick}>
              Yesterday
            </button>
            <button className='dark-blue' onClick={handleClick}>
              Today
            </button>
            <button className='dark-blue' onClick={handleClick}>
              Tomorrow
            </button>
          </div>
        </div>
      </section>

      {/* BET OPTIONS 2 */}
      <section className='bet-options-2'>
        <ul>
          <li>
            <a href={predict4MeLink} target='_blank' rel='noreferrer'>
              Predict 4 Me 🔥
            </a>
          </li>
          <li className='active'>
            <Link to='/over-1.5-tips'>Over 1.5</Link>
          </li>
          <li>
            <Link to='/over-2.5-tips'>Over 2.5</Link>
          </li>
          <li>
            <Link to='/bttsgg-tips'>BTTS/GG</Link>
          </li>
          <li>
            <Link to='/double-chance-tips'>Double Chance</Link>
          </li>
          <li>
            <Link to='/handicap-tips'>Handicap</Link>
          </li>
        </ul>
      </section>

      <AdTelegram />
    </main>
  );
};

export default OverOnePointFive;
