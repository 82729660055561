import React from 'react';
import { FaTelegramPlane } from 'react-icons/fa';
import { useGlobalContext } from '../../context/context';
import { TelegramAdSkeleton } from '../loaders/Loaders';

const AdTelegram = () => {
  const { telegramLink, adOne } = useGlobalContext();

  return (
    <section
      className={`ad-space-telegram ${
        adOne.adLink?.toLowerCase() === `no ad` ? `no-ad` : ``
      }`}
    >
      {adOne.adImage ? (
        <>
          {adOne.adLink?.toLowerCase() === `no ad` ? null : (
            <a
              href={adOne.adLink}
              target='_blank'
              rel='noreferrer'
              className='ad-space'
            >
              <img
                src={`https://api.kmanalysis.com/${adOne.adImage}`}
                alt={adOne.adCaption}
              />
              <h3 className='caption'>{adOne.adCaption}</h3>
            </a>
          )}
        </>
      ) : (
        <TelegramAdSkeleton />
      )}
      <div className='telegram'>
        <h2>Join our Telegram Community</h2>
        <p>
          Get first hand updates and do not miss out on profitable prediction
          tips by joining our telegram community today
        </p>
        <div className='btn'>
          <a href={telegramLink} target='_blank' rel='noreferrer'>
            <button className='blue'>
              <FaTelegramPlane /> Join Telegram Channel
            </button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default AdTelegram;
