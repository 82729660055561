import React from 'react';
import { FaTimes } from 'react-icons/fa';

const GifBanner = ({ data, dismissFunc }) => {
  const { link, mobileImage, desktopImage } = data;
  return (
    <div className='gif-banner'>
      <img
        src={`https://api.kmanalysis.com/${mobileImage}`}
        alt='gif banner'
        className='mobile'
      />
      <img
        src={`https://api.kmanalysis.com/${desktopImage}`}
        alt='gif banner'
        className='desktop'
      />
      <a
        href={link}
        target='_blank'
        rel='noreferrer'
        onClick={() => dismissFunc(false)}
      >
        {' '}
      </a>
      <div className='close-btn' onClick={() => dismissFunc(false)}>
        <FaTimes />
      </div>
    </div>
  );
};

export default GifBanner;
