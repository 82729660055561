import React, { useEffect, useState } from 'react';
import FreeBetsOptions from '../../components/free-bets-options/FreeBetsOptions';
import AdTelegram from '../../components/ad-telegram/AdTelegram';
import axios from 'axios';
import { useGlobalContext } from '../../context/context';
import { ExpertSingleTipSkeleton } from '../../components/loaders/Loaders';
import ExpertSingle from '../../components/expert-single/ExpertSingle';

const ExpertSingles = () => {
  const [expertSingles, setExpertSingles] = useState([]);
  const [loading, setLoading] = useState(true);
  const { baseURL } = useGlobalContext();

  const getExpertSingles = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/expert-singles-tips`);
      setLoading(false);
      setExpertSingles(data.tips);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getExpertSingles();
    // eslint-disable-next-line
  }, []);

  return (
    <main className='expert-singles-page'>
      <div className='options'>
        <FreeBetsOptions itemBgColor={`#509eff`} />
      </div>
      <h1 className='title'>
        Free <span>Expert</span> Singles
      </h1>

      {!loading && expertSingles.length < 1 ? (
        <div className='tips-unavailable'>
          <h2>Free Expert Singles Currently Unavailable</h2>
        </div>
      ) : (
        <div className='tips'>
          {loading ? (
            <ExpertSingleTipSkeleton cards={6} />
          ) : (
            <>
              {expertSingles.map((tip) => {
                const {
                  awayTeam,
                  homeTeam,
                  awayTeamLogo,
                  homeTeamLogo,
                  competitionLogo,
                  _id,
                  matchTime,
                } = tip;
                return (
                  <ExpertSingle
                    key={_id}
                    awayTeam={awayTeam}
                    awayTeamLogo={awayTeamLogo}
                    competitionLogo={competitionLogo}
                    homeTeam={homeTeam}
                    homeTeamLogo={homeTeamLogo}
                    id={_id}
                    matchTime={matchTime}
                  />
                );
              })}
            </>
          )}
        </div>
      )}

      <AdTelegram />
    </main>
  );
};

export default ExpertSingles;
