import React from 'react';
import { AiOutlineClockCircle } from 'react-icons/ai';
import freeExpertSinglesVS from '../../assets/vs-icon.png';
import moment from 'moment';
import { Link } from 'react-router-dom';

const ExpertSingle = ({
  homeTeam,
  awayTeam,
  matchTime,
  homeTeamLogo,
  awayTeamLogo,
  competitionLogo,
  id,
}) => {
  return (
    <Link to={`/expert-singles/${id}`} className='expert-single'>
      <div className='competition'>
        <img src={competitionLogo} alt='club' />
      </div>
      <div className='teams'>
        <div className='team home'>
          <h3>{homeTeam}</h3>
          <img src={homeTeamLogo} alt={homeTeam} />
        </div>
        <div className='vs'>
          <img src={freeExpertSinglesVS} alt='vs' />
        </div>
        <div className='team away'>
          <h3>{awayTeam}</h3>
          <img src={awayTeamLogo} alt={awayTeam} />
        </div>
      </div>
      <div className='time'>
        <h4>{moment(matchTime).format(`D MMMM YYYY`)}</h4>
        <p>
          <AiOutlineClockCircle />
          {moment(matchTime).format(`h:mm a`)}
        </p>
      </div>
    </Link>
  );
};

export default ExpertSingle;
