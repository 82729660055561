import React, { useEffect, useState } from "react";
import { MainNewsCard, SubNewsCard } from "../../components/News-Card/NewsCard";
import axios from "axios";
import { useGlobalContext } from "../../context/context";
import { PageLoader } from "../../components/loaders/Loaders";
import ReactPaginate from "react-paginate";

const NewsUpdates = () => {
  const [news, setNews] = useState([]);
  const { baseURL, adOne, adTwo } = useGlobalContext();
  const [loading, setLoading] = useState(true);
  const [newsCount, setNewsCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);

  const getAllNews = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/news?page=${pageNum}`);
      setLoading(false);
      setNews(data.news);
      setNewsCount(data.count);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllNews();
    // eslint-disable-next-line
  }, [pageNum]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [loading, pageNum]);

  const itemsPerPage = 9;
  const pageCount = Math.ceil(newsCount / itemsPerPage);
  const handlePageClick = (event) => {
    setPageNum(event.selected + 1);
  };

  if (loading) {
    return <PageLoader />;
  }

  return (
    <main className="news-updates-page">
      {/* AD SPACE */}
      {adTwo.adLink?.toLowerCase() === `no ad` ? null : (
        <article className="ad-space">
          <a href={adTwo.adLink} target="_blank" rel="noreferrer">
            <img
              src={`https://api.kmanalysis.com/${adTwo.adImage}`}
              alt={adTwo.adCaption}
            />
            <h3 className="caption">{adTwo.adCaption}</h3>
          </a>
        </article>
      )}

      {news && (
        <>
          <div className="main-news">
            <MainNewsCard
              sportCategory={news[0].sportCategory}
              timePosted={news[0].createdAt}
              title={news[0].title}
              image={news[0].image}
              content={news[0].content}
              id={news[0]._id}
            />
          </div>

          <div className="subs">
            {news.slice(1, 5).map((news) => {
              const { sportCategory, createdAt, title, image, _id } = news;
              return (
                <SubNewsCard
                  sportCategory={sportCategory}
                  timePosted={createdAt}
                  title={title}
                  image={image}
                  id={_id}
                  key={_id}
                />
              );
            })}
          </div>

          {/* AD SPACE */}
          {adOne.adLink?.toLowerCase() === `no ad` ? null : (
            <article className="ad-space second">
              <a href={adOne.adLink} target="_blank" rel="noreferrer">
                <img
                  src={`https://api.kmanalysis.com/${adOne.adImage}`}
                  alt={adOne.adCaption}
                />
                <h3 className="caption">{adOne.adCaption}</h3>
              </a>
            </article>
          )}

          <div className="subs">
            {news.slice(5).map((news) => {
              const { sportCategory, createdAt, title, image, _id } = news;
              return (
                <SubNewsCard
                  sportCategory={sportCategory}
                  timePosted={createdAt}
                  title={title}
                  image={image}
                  id={_id}
                  key={_id}
                />
              );
            })}
          </div>

          <ReactPaginate
            breakLabel="..."
            nextLabel="Next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={1}
            pageCount={pageCount}
            previousLabel="< Prev"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="page-num"
            activeLinkClassName="active"
            previousClassName="btn prev"
            nextClassName="btn next"
          />
        </>
      )}
    </main>
  );
};

export default NewsUpdates;
