import React from 'react';
import { FaTimes } from 'react-icons/fa';

const PopUp = ({ data, dismissFunc }) => {
  const { link, image } = data;

  return (
    <section className='pop-up-container'>
      <div className='pop-up'>
        <img src={`https://api.kmanalysis.com/${image}`} alt='' />
        <a
          href={link}
          target='_blank'
          rel='noopener noreferrer'
          onClick={() => dismissFunc(false)}
        >
          {' '}
        </a>
        <div className='close-btn' onClick={() => dismissFunc(false)}>
          <FaTimes />
        </div>
      </div>
    </section>
  );
};

export default PopUp;
