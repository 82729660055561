import { FaCheckSquare, FaTimes } from 'react-icons/fa';

export const FreeRegularTip = ({
  matchTime,
  homeTeam,
  awayTeam,
  status,
  league,
  prediction,
  index,
  extraClass,
  result,
}) => {
  return (
    <div
      className={`prediction ${extraClass} ${
        index % 2 !== 0 ? `light-bg ` : ``
      }${!prediction ? `five` : ``}`}
    >
      <h4 className='league'>{league}</h4>
      <div className='teams'>
        <h3>{homeTeam}</h3>
        <span>vs</span>
        <h3>{awayTeam}</h3>
      </div>
      {prediction ? <h4 className='tip'>{prediction}</h4> : null}
      <p className='time'>{matchTime}</p>
      <p className='result'>{result}</p>
      <div className='cont'>
        {status === `pending` ? (
          <p>-</p>
        ) : (
          <>
            <div className={`status`}>
              <p>{status}</p>
              {status === `won` ? (
                <FaCheckSquare className={status} />
              ) : status === `lost` ? (
                <FaTimes className={status} />
              ) : null}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
